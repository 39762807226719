<template>
    <div class="goods">
      <van-sticky :offset-top="0">
        <demo-block :title="('')">
          <van-nav-bar :title="('充值')" :left-text="('返回')" left-arrow @click-left="this.$router.push('index')">
            <template #right>
              <van-icon v-if="paytype===0 || paytype ===1" name="wechat-pay" size="18" />
              <van-icon v-if="paytype===2" name="alipay" size="18" />
            </template>
          </van-nav-bar>
        </demo-block>
      </van-sticky>
      <van-cell-group inset>
        <van-field
            v-model="outid"
            center
            clearable
            label="输入学号"
            placeholder="请输入学号信息"
            :border="false"
            use-button-slot
        />
        </van-cell-group>
      <van-cell-group inset style="margin-bottom:20px">
        <van-cell title="前期充值">
          <van-dropdown-menu  style="margin-top:-10px;">
            <van-dropdown-item v-model="curOutid" :options="lsRecentPeople" input-align="right" />
        </van-dropdown-menu>
        </van-cell>
      </van-cell-group>
       <van-cell-group inset>
        <van-cell title="选择充值金额" />
        
        <van-cell>
             <van-radio-group v-model="fare" direction="horizontal" >
                <van-radio v-for="i in fareList" :key="i.fare" :name="i.fare" shape="square" style="margin-bottom:20px">{{i.fare === 0 ? '自定义金额' : i.fare}}</van-radio>
            </van-radio-group>
        </van-cell>
      </van-cell-group>
     
      <van-cell-group inset v-if="fare === 0">
        <van-field
            v-model="curfare"
            center
            clearable
            label="自定义金额"
            placeholder="请输入自定义金额"
            :border="false"
            use-button-slot
        >
        </van-field>
      </van-cell-group>
      <van-action-bar>
        <van-action-bar-button v-if="ispay===true" type="warning" @click="ToPay">
          {{payname}}
        </van-action-bar-button>
        <van-action-bar-button type="danger" @click="this.$router.push('index')">
          返回
        </van-action-bar-button>
      </van-action-bar>

      <van-overlay :show="isLoading" >
        <view class="wrapper">
          <view class="block" catch:tap="noop">
            <van-loading size="24px" color="#ffffff">{{loadcontent}}</van-loading>
          </view>
        </view>
      </van-overlay>
      
    </div>
  </template>
  
  <script>
  import { GetBrowserType } from '../../utils/tools/index'
  import { requestGetPayUrl, requestGetPayRecentInfo } from '../../api/api'
  import { mapState, mapGetters, mapActions } from 'vuex'

  import {
    Tag,
    Col,
    Icon,
    Cell,
    CellGroup,
    Swipe,
    Toast,
    SwipeItem,
    ActionBar,
    ActionBarIcon,
    ActionBarButton,
    NavBar,
    Sticky,
    Button,
    Field,
    DropdownMenu,
    DropdownItem,
    Radio,
    RadioGroup,
    Dialog,
    Overlay,
    Loading
  } from 'vant';
  
  export default {
    components: {
      [Tag.name]: Tag,
      [Col.name]: Col,
      [Icon.name]: Icon,
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup,
      [Swipe.name]: Swipe,
      [SwipeItem.name]: SwipeItem,
      [ActionBar.name]: ActionBar,
      [ActionBarIcon.name]: ActionBarIcon,
      [ActionBarButton.name]: ActionBarButton,
      [NavBar.name]: NavBar,
      [Sticky.name]: Sticky,
      [Button.name]: Button,
      [Field.name]: Field,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
      [Radio.name]: Radio,
      [RadioGroup.name]: RadioGroup,
      [Dialog.name]: Dialog,
      [Overlay.name]: Overlay,
      [Loading.name]: Loading
    },
    computed: {
    ...mapState({
      token: state => state.token,
      isbind: state => state.isbind,
      userinfo: state => state.userinfo,
      accesstoken: state => state.accesstoken,
      openid: state => state.openid,
      typekey: state => state.typekey
    }),
    ...mapGetters([
      'openidex',
      'hasopenid',
      'isReAuthLogin',
      'isGrid'
    ])
  },
    data() {
      return {
        loadcontent: '加载中...',
        isLoading: false,
        payname: '支付',
        ispay: false,
        paytype: -1,
        fare: 50,
        curfare: 0.00,
        iscustmoney: false,
        curOutid: 'test001',
        outid: 'test001',
        fareList: [
          { fare: 10 },
          { fare: 30},
          { fare: 50},
          { fare: 100},
          { fare: 150},
          { fare: 200},
          { fare: 300},
          { fare: 0},
        ],
        lsRecentPeople: [
            { text: '张三', value: 'test001' },
            { text: '李四', value: '20200102' },
        ],
      }
    },
    watch: {
      curOutid(val) {
      console.log(val)
        this.outid = val
    }
    },
    mounted() {
        this.paytype = GetBrowserType()
        console.log(this.getLocalLoginInfo())
        console.log(this.$store)
        this.ispay= true
        if (this.paytype===0 || this.paytype === 1) this.payname="微信支付"
        else if (this.paytype === 2) this.payname="支付宝支付"
        else {
            this.payname = '不支持'
            this.ispay = false
        }
        this.outid = this.$store.state.userinfo.outid
        this.GetRecentPayInfo()
    },
    methods: {
      /* eslint-disable */
      ToWxPay(data) {
        var vm = this;
        //下面是解决WeixinJSBridge is not defined 报错的方法
        if (typeof WeixinJSBridge == "undefined") {
          //微信浏览器内置对象。参考微信官方文档
          if (document.addEventListener) {
            document.addEventListener(
              "WeixinJSBridgeReady",
              vm.onBridgeReady(data),
              false
            );
          } else if (document.attachEvent) {
            document.attachEvent("WeixinJSBridgeReady", vm.onBridgeReady(data));
            document.attachEvent("onWeixinJSBridgeReady", vm.onBridgeReady(data));
          }
        } else {
          vm.onBridgeReady(data);
        }
      },
      onBridgeReady(data) {
        console.log('111111start to wxpay  info  data:===>' + data)
        let jsonData = JSON.parse(data);
        // console.log('解析json成功')
        // console.log(jsonData);
        //window.alert('解析json成功，开始送过去支付')
        // Toast('解析json成功，开始送过去支付~');
        // console.log(WeixinJSBridge)
        // console.log('start alert ')
        // Toast(' ~'+WeixinJSBridge);
        window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
          "appId": jsonData.app_id,        //公众号ID，由商户传入     
          "timeStamp": jsonData.time_stamp,        //时间戳，自1970年以来的秒数     
          "nonceStr": jsonData.nonce_str,       //随机串     
          "package": jsonData.package,
          "signType": jsonData.sign_type,       //微信签名方式：     
          "paySign": jsonData.pay_sign
        },
        function(res) {
            // window.alert(res.err_msg)
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              Dialog.alert({
                title: '成功提醒',
                message: '支付成功，等待微信服务器返回' ,
              }).then(() => {
                console.log('onclose')
              });
            }
        });
      },
      /* eslint-enable */
      ...mapActions([
        'getLocalLoginInfo',
        'getIsNeedAuthLogin',
        'clearAccessToken'
      ]),
      async GetRecentPayInfo() {
        const res = await requestGetPayRecentInfo({accesstoken: this.$store.state.accesstoken})
        console.log(res)
        if (res.return_code === 'SUCCESS') {
          if (res.lastoutid !==null && res.lastoutid !='') this.outid = res.lastoutid
          this.bindinfo = res.bindinfo
          this.lsRecentPeople= []
          res.bindinfo.forEach( item => {
            this.lsRecentPeople.push({text: item.name,value: item.outid})
          });

        }
      },
      async ToPay() {

        Dialog.confirm({
          title: '充值确认',
          message: '您确认要用' + this.payname + '给' + this.outid + '进行充值吗？',
        })
          .then(async () => {
            // on confirm
            this.isLoading = true
            // Toast('开始送去' + this.payname + '')
            this.loadcontent = '开始送去' + this.payname + ''
            let fare = this.fare
            if(this.fare===0) fare = this.curfare
            const param = {paytype: this.paytype, fare: fare, outid: this.outid, access_token: this.accesstoken, token: this.token}
            console.log(param)
            const res = await requestGetPayUrl(param)
            console.log(res)
            if (res.return_code === '0')
            {
              if(this.paytype===2) {
                window.open(res.data1);
                return;
              } else if (this.paytype === 0) {
                console.log('微信支付'+res.data2)
                this.ToWxPay(res.data2)
              }
              setTimeout(() => {
                this.isLoading = false  
              }, 1000);

            } else {
              Dialog.alert({
                title: '错误提醒',
                message: '获取支付失败：' + res.return_message,
              }).then(() => {
                // on close
              });
              setTimeout(() => {
                this.isLoading = false  
              }, 1000);
              
            }
          })
          .catch(() => {
            return;
          });

        


      },
      formatPrice() {
        console.log(this.goods)
        return '¥' + (this.goods.price / 1).toFixed(2);
      },
  
      onClickCart() {
        this.$router.push({ path: 'cart?' + new Date() });
      },
  
      sorry() {
        Toast('暂无后续逻辑~');
      },
    },
  };
  </script>
  
  <style lang="less">
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 220px;
    height: 50px;
    // background-color: #fff;
  }
  </style>
  